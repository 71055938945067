import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

import { ReactComponent as InstagramIcon } from "../../images/instagram-icon-dark.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon-dark.svg";
import { ReactComponent as WebsiteIcon } from "../../images/website-icon-dark.svg";

const Subheading = tw(SubheadingBase)`text-center`;
const MeetTheMakers = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const MakerContainer = tw.div`mt-16 lg:w-1/2`;
const Maker = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-40 h-40 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const MakerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

function GetSocialIcon(type) {
  if (type === "instagram") {
    return <InstagramIcon />;
  } else if (type === "twitter") {
    return <TwitterIcon />
  }
  return <WebsiteIcon />;
}

export default ({
  subheading = "",
  heading = "Meet the Makers",
  makers = [
    {
      imageSrc: "images/roddy.jpg",
      quote: "Specializing in iOS app development, Roddy has 10 years of programming experience under his belt. Roddy has worked on numerous apps for iOS, including Classifier, Bleacher Report, and theScore.",
      makerName: "Roddy Munro",
      socials: [
        { type: "twitter", link: "https://iosdev.space/@podomunro" },
        { type: "website", link: "https://roddy.io" }
      ]
    },
    {
      imageSrc: "images/danika.jpg",
      quote: "Danika Vautour is a Canadian visual artist based in New Brunswick. She specialises in ceramics and has been working with the material for nearly 9 years.",
      makerName: "Danika Vautour",
      socials: [
        { type: "instagram", link: "https://instagram.com/danikavautour" },
        { type: "website", link: "https://danikavautour.ca" }
      ]
    }
  ]
}) => {
  return (
    <Container id="makers">
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <MeetTheMakers>
          {makers.map((maker, index) => (
            <MakerContainer key={index}>
              <Maker>
                <Image src={maker.imageSrc} />
                <MakerName>{maker.makerName}</MakerName>
                <Quote>{maker.quote}</Quote>
                  <SocialLinksContainer>
                    {maker.socials.map((social) => (
                        <SocialLink href={social.link}>
                          {GetSocialIcon(social.type)}
                        </SocialLink>
                    ))}
                </SocialLinksContainer>
              </Maker>
            </MakerContainer>
          ))}
        </MeetTheMakers>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
