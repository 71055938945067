import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js"
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";
import { ReactComponent as EmailIcon } from "../../images/email-newsletter-icon.svg";


const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoLink = styled.div`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-48 mr-3`}
  }
`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoLink href="/">
            <img src="images/logo_dark_transparent.png" alt="logo" />
          </LogoLink>
          <LinksContainer>
            <Link href="#features">Features</Link>
            <Link href="#pricing">Pricing</Link>
            <Link href="#makers">Makers</Link>
            <Link href="#newsletter">Newsletter</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://instagram.com/ceramispace">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="mailto:hello@appdeco.ca">
              <EmailIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2023, App Deco Studio. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
