import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import HomePage from "pages/HomePage.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TermsOfService from "pages/TermsOfService";
import PrivacyPolicy from "pages/PrivacyPolicy";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path='/survey' component={() => { 
          window.location.href = 'https://danikavautour.ca/ceramispace-survey'; 
          return null;
        }}/>
        <Route path='/download' component={() => { 
          window.location.href = 'https://apps.apple.com/app/ceramispace/id1526050930'; 
          return null;
        }}/>
        <Route path='/beta' component={() => { 
          window.location.href = 'https://testflight.apple.com/join/8Cq0rRet'; 
          return null;
        }}/>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  );
}