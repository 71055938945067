import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAtom, faList, faFire, faCalendar, faCloudDownloadAlt, faWeight, faChartLine, faClipboardList, faMugHot } from '@fortawesome/free-solid-svg-icons'

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-300 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {

  const cards = [
    { icon: faAtom, title: "Store Glaze Recipes", description: "Store all of your glaze recipes in one place, and have new batch sizes calculated within seconds." },
    { icon: faFire, title: "Calculate Firing Schedules", description: "Have the durations of your firing schedule calculated automatically for you, accompanied with beautiful firing charts." },
    { icon: faList, title: "Keep Inventory", description: "Always know what materials you currently have and how much, wherever you are." },
    { icon: faCalendar, title: "Record Firing Logs", description: "Keep track of past and future kiln firings, and get push notifications when your firings complete." },
    { icon: faChartLine, title: "Live Firing Widgets", description: "Get live firing updates directly to your device's home screen." },
    { icon: faClipboardList, title: "Track Ongoing Projects", description: "Track your ongoing projects with our highly customizable project boards." },
    { icon: faMugHot, title: "Manage Products", description: "Manage your products and link them with your glaze recipes and projects." },
    { icon: faWeight, title: "Add Raw Materials", description: "Add to our large database of raw materials, complete with molecular composition." },
    { icon: faCloudDownloadAlt, title: "Sync Across Devices", description: "Your data synchronizes across all of your devices without the need for an account, allowing you to seamlessly transition from one device to the next." }
  ];

  return (
    <Container id="features">
      <ThreeColumnContainer>
        <Heading>App Features</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <FontAwesomeIcon icon={card.icon} size="lg" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
